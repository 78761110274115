<template>
  <div class="accesses-container tw-w-full">
    <zem-card class="tw-flex tw-items-center">
      <zem-card-title class="mb-0">{{ newElement ? 'Создание заказа' : 'Редактирование заказа' }}</zem-card-title>
      <div>
        <template v-if="!editElement && !newElement">
          <img
            v-if="$can('edit', 'role-update')"
            :src="require('@/assets/icons/edit.svg')"
            alt=""
            class="block-id__edit ml-3"
            @click="editElement = true"
          />
          <img :src="require('@/assets/icons/close.svg')" alt="" class="block-id__delete" @click="closeRightSidebar" />
        </template>
        <template v-else>
          <zem-link class="mr-2" @click="closeRightSidebar">Отменить</zem-link>
          <zem-button
            :loading="loadingNewElement"
            @click="$emit(!editElement ? 'on-create' : 'on-update', currentItem)"
          >
            Сохранить
          </zem-button>
        </template>
      </div>
    </zem-card>

    <div class="zem-collapse-table rows mt-3 mb-3">
      <div class="zem-collapse-table__row" style="grid-template-columns: 1fr 2fr">
        <div class="zem-collapse-table__column title mt-1">Название</div>
        <div v-if="!editElement && !newElement" class="zem-collapse-table__column text mt-1">
          {{ currentItem['title'] }}
        </div>
        <ZemInput
          v-else
          :value="currentItem['title']"
          class="mt-0"
          type="text"
          @input="e => (currentItem['title'] = e)"
        />

        <div class="zem-collapse-table__column title mt-1">Статус</div>
        <div v-if="!editElement && !newElement" class="zem-collapse-table__column text mt-1">
          {{ currentItem['status'] }}
        </div>
        <ZemDropdownList v-else v-model="currentItem['status']" :options="orderStatuses" class="mt-0" />

        <div class="zem-collapse-table__column title mt-1">Ответственный</div>
        <div v-if="!editElement && !newElement" class="zem-collapse-table__column text mt-1">
          {{ currentItem['responsibleBy'].title }}
        </div>
        <ZemDropdownList v-else v-model="currentItem['responsibleBy']" :options="users ? users : []" class="mt-0" />

        <div class="zem-collapse-table__column title mt-1">Бюджет</div>
        <div v-if="!editElement && !newElement" class="zem-collapse-table__column text mt-1">
          {{ currentItem['budget'] }}
        </div>
        <ZemInput
          v-else
          :value="currentItem['budget']"
          class="mt-0"
          type="number"
          @input="e => (currentItem['budget'] = e)"
        />

        <div class="zem-collapse-table__column title mt-1">Объект</div>
        <div v-if="!editElement && !newElement" class="zem-collapse-table__column text mt-1">
          {{ currentItem['object'].title }}
        </div>
        <ZemDropdownList
          v-else
          v-model="currentItem['object']"
          :options="$store.state.objects.elements ? $store.state.objects.elements : []"
          class="mt-0"
        />
      </div>
    </div>

    <zem-collapse is-opened-default title="Участники">
      <div class="zem-collapse-table rows">
        <div
          v-for="(item, index) in persons"
          :key="index"
          class="zem-collapse-table__row tw-grid-cols-[1fr_1fr_1fr_auto]"
        >
          <ZemDropdownList
            v-model="item['community_status']"
            :options="communityStatuses"
            class="mt-0"
            placeholder="Участник отношений"
          />
          <ZemDropdownList
            v-model="item['human_position']"
            :options="humanPositions"
            class="mt-0"
            placeholder="Должность"
          />
          <ZemDropdownList
            v-model="item['human']"
            :loading="isLoadingPeoples"
            :options="peoples ? peoples : []"
            class="mt-0"
            search
            placeholder="ФИО"
            @open="getPeoples"
          />
          <div class="zem-collapse-table__row text text-h cursor-pointer tw-w-[16px] tw-flex">
            <img
              :src="require('@/assets/icons/close-r.svg')"
              alt=""
              class="delete_item"
              @click="persons.splice(index, 1)"
            />
          </div>
        </div>
      </div>
      <button
        class="tw-flex tw-items-center tw-text-xs tw-mt-2 text-primary tw-font-semibold"
        @click="persons.push(Object.assign({}, defaultPerson))"
      >
        <PlusIcon class="tw-mr-1 tw-font-bold" size="14" stroke-width="3" />
        Участник
      </button>
    </zem-collapse>
  </div>
</template>

<script>
import ZemCard from '@/components/ui/ZemCard'
import ZemCardTitle from '@/components/ui/ZemCardTitle'
import ZemCollapse from '@/components/ui/ZemCollapse'
import ZemInput from '@/components/ui/ZemInput'
import ZemDropdownList from '@/components/ui/ZemDropdownList'
import ZemSelect from '@/components/ui/Select.vue'
import ZemMultiSelect from '@/components/ui/ZemMultiSelect.vue'
import ZemLink from '@/components/ui/ZemLink.vue'
import ZemButton from '@/components/ui/ZemButton.vue'
import ZemRadioButton from '@/components/ui/ZemRadioButton.vue'
import ZemCheckbox from '@/components/ui/Checkbox.vue'
import ZemPreloader from '@/components/ui/ZemPreloader.vue'
import PreloaderWrapper from '@/components/PreloaderWrapper.vue'
import OrdersService from '@/services/orders.service'
import UsersService from '@/services/users.service'
import ObjectsService from '@/services/objects.service'
import OrderPerformersBlock from '@/components/Orders/OrderPerformersBlock.vue'
import OrderCustomersBlock from '@/components/Orders/OrderPersonsBlock.vue'
import {PlusIcon} from 'vue-feather-icons'
import OtherService from '@/services/other.service'
import PeopleAndCompaniesService from '@/services/peopleAndCompanies.service'

export default {
  emits: ['on-create', 'on-close', 'new-state'],
  components: {
    OrderCustomersBlock,
    OrderPerformersBlock,
    PreloaderWrapper,
    ZemPreloader,
    ZemCheckbox,
    ZemRadioButton,
    ZemSelect,
    ZemCardTitle,
    ZemCard,
    ZemCollapse,
    ZemInput,
    ZemDropdownList,
    ZemMultiSelect,
    ZemLink,
    ZemButton,
    PlusIcon,
  },
  props: {
    currentItem: {
      type: Object,
      default: () => {},
    },
    newElement: {
      type: Boolean,
      default: false,
    },
    editElement: {
      type: Boolean,
      default: false,
    },
    loadingNewElement: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      newElementTitle: '',
      data: null,
      testElements: [
        {
          title: 'Название роли',
          value: 'Снабженец',
        },
        {
          title: 'Комментарий',
          value: 'Справочник + продукты',
        },
      ],
      persons: [],
      peoples: [],
      defaultPerson: {
        community_status: {
          title: null,
          value: null,
        },
        human_position: {
          title: null,
          value: null,
        },
        human: {
          title: null,
          value: null,
        },
      },
      schemaData: [],
      orderStatuses: [],
      communityStatuses: [],
      humanPositions: [],
      isLoadingPeoples: false,
    }
  },
  mounted() {
    OrdersService.getOrderStatuses().then(r => {
      this.orderStatuses = r.data.data.map(status => ({
        title: `${status.pipeline.data.name} - ${status.name}`,
        value: status.id,
      }))
    })
    OtherService.getCommunityStatuses().then(r => {
      this.communityStatuses = r.data.data.map(el => ({
        title: el.title,
        value: el.id,
      }))
    })
    OtherService.getHumanPositions().then(r => {
      this.humanPositions = r.data.data.map(el => ({
        title: el.title,
        value: el.id,
      }))
    })
    UsersService.getAllUsers(true)
    ObjectsService.getObjects(true)
  },
  computed: {
    users() {
      return this.$store.state.users.users
    },
    humans() {
      return this.$store.state.users.users.map(user => ({
        title: user.human.data.fio,
        value: user.human.data.id,
      }))
    },
  },
  methods: {
    closeRightSidebar() {
      this.edit = false
      this.newElement = false
      this.$emit('new-state', false)
      this.$emit('on-close')
      this.$store.commit('sidebars/changeRightSidebar', false)
    },
    getPeoples() {
      this.isLoadingPeoples = true
      PeopleAndCompaniesService.getPeoplesList().then(r => {
        this.peoples = r
        this.isLoadingPeoples = false
      })
    },
  },
}
</script>

<style lang="scss">
.details-form__info-container {
  .zem-input {
    margin-top: 0;
  }
}

.accesses-container {
  > .zem-card:first-child {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > div:last-child {
      display: flex;

      img:not(:first-child) {
        margin-left: 8px;
      }

      .block-id__delete {
        width: 17px;
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.accesses-container {
  height: 100%;
  padding: 20px;
  overflow-y: auto;

  .block-id {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: $color-mine-shaft;

    &__edit {
      cursor: pointer;
    }

    &__label {
      width: 6px;
      height: 6px;
      background: #2eb67d;
      border-radius: 100px;
      margin-right: 5px;
      margin-bottom: 2px;
    }
  }
}

.colored {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: $color-accent;

  img {
    margin-right: 3px;
  }

  &--link {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.details-form {
  &__title-block {
    font-weight: 400;
    font-size: 12px;
    color: $color-gull-gray;
    margin-right: 20px;
    flex: 1;
  }

  &__data-block {
    font-weight: 400;
    font-size: 12px;
    color: $color-mine-shaft;
    flex: 1;
  }

  &__info-container {
    display: flex;
    align-items: center;
    align-content: flex-start;
    text-align: left;

    .zem-dropdown {
      margin-top: 0;
    }

    .zem-textarea {
      margin-top: 0;
    }
  }

  &__info-container:not(:first-child) {
    margin-top: 6px;
  }

  &__btn-block {
    width: 140px;
  }
}

.zem-input-container {
  input {
    margin-top: 0;
  }
}

@media screen and (max-width: 1024px) {
  .details-form {
    width: 100%;
    margin: 0;
    padding: 90px 20px 20px 20px;
    display: flex;
    flex-direction: column;

    &__container {
      overflow-x: auto;
    }
  }
}
</style>
