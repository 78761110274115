<template>
  <!-- Перевод добавлен -->
  <div class="filter">
    <div class="filter__label tw-items-start">
      <div class="d-flex" style="flex-direction: column; align-items: flex-start">
        <span>Воронки заказов</span>
        <zem-link
          class="mt-1 text-normal d-flex justify-content-end"
          style="position: relative; bottom: -2px"
          @click="handleNodeClickAway"
          >{{ $t('Reset all') }}
        </zem-link>
      </div>
      <div @click="() => $store.commit('sidebars/changeLeftSidebar', false)">
        <zem-icon class="cursor-pointer" name="close" size="18" />
      </div>
    </div>
    <div v-if="breadCrumbsList.length > 0" class="filter__bread-crumbs">
      <div>
        <span>{{ breadCrumbsList.slice(0, -1).join(' / ') }}</span>
        <span class="filter__bread-crumbs__last">
          {{ breadCrumbsList.length > 1 ? '/' : '' }} {{ breadCrumbsList[breadCrumbsList.length - 1] }}
        </span>
      </div>
    </div>
    <p v-if="showCreateFirstDirectory" class="zem-link" @click="addFirstCategory">
      {{ $t('Create the first stage') }}
    </p>
    <template v-if="!showCreateFirstDirectory">
      <OrdersTree
        v-if="$can('edit', 'position-group-read')"
        ref="treeContainer"
        :checkboxes="$store.state.orders.activeMultipleChoice"
        :draggable="$can('edit', 'orders-drag')"
        :is-loading-tree="$store.state.other.isLoadingSidebar"
        :list="elements"
        @on-change-orders="updateCount"
        @on-change-show-settings="$emit('on-change-show-settings', $event)"
      />
    </template>
  </div>
</template>

<script>
import Checkbox from '@/components/ui/Checkbox'
import {eventBus} from '@/main'
import ZemLink from '@/components/ui/ZemLink.vue'
import OrdersTree from '@/components/ui/OrdersTree.vue'
import OrdersService from '@/services/orders.service'
import ZemIcon from '@/components/ui/ZemIcon.vue'

export default {
  components: {
    ZemIcon,
    OrdersTree,
    Checkbox,
    ZemLink,
  },

  data() {
    return {
      elements: [],
      showCreateFirstDirectory: undefined,
    }
  },

  computed: {
    breadCrumbsList() {
      return this.$store.state.orders.breadCrumbs.reverse()
    },
  },

  mounted() {
    this.elements = this.$store.state.orders.tree
    eventBus.$on('update-elements-tree', () => {
      if (this.$store.state.orders.tree.length === 0) this.showCreateFirstDirectory = true
      this.elements = this.$store.state.orders.tree
    })
  },

  methods: {
    addFirstCategory() {
      this.$store.commit('orders/updateTree', [
        {
          title: '',
          real_id: 0,
          edit: true,
          new: true,
          children: [],
        },
      ])
      this.elements = this.$store.state.orders.tree
      this.showCreateFirstDirectory = false
    },
    updateCount({count, section}) {
      this.elements.forEach(element => {
        element.children.forEach(child => {
          if (child.id === section) {
            child.total_orders = count
          }
        })
      })
    },
    handleNodeClickAway() {
      this.$store.commit('orders/getSearch', '')
      this.$store.commit('orders/itemsForDelete', [])
      this.$store.commit('sidebars/changeRightSidebar', false)
      this.$store.commit('orders/selectedSection', {
        title: '',
        id: '',
      })
      this.$refs.treeContainer.filterText = ''

      OrdersService.getAllOrders()

      this.$store.commit('orders/breadCrumbs', [])

      this.$refs.treeContainer.$refs.tree.setCurrentNode([])
    },
  },
}
</script>

<style lang="scss">
.filter {
  display: block;
  padding: 20px 24px;
  min-width: 192px;
  overflow: auto;
  height: 100%;

  .checkbox-dnd {
    margin-bottom: 6px;
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    color: $color-mine-shaft;
  }

  &__bread-crumbs {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: $color-gull-gray;
    height: 14px;

    &__last {
      color: $color-accent;
    }
  }

  > .drag-area {
    margin-left: 0;
    height: auto;
    transition: all 0.4s ease;
    overflow: unset;

    > .item-group {
      display: block;

      .drag-area__name {
        font-size: 14px;
        line-height: 19px;
        min-height: 26px;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .filter-products {
    padding: 20px;
    min-width: 152px;
  }
}
</style>